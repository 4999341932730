<template>
  <div class="bg-white">
    <div
      class="flex flex-col sm:flex-row sm:justify-between sm:items-center px-4 py-4 mt-2 bg-white"
    >
      <TitlePlus
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.headline'
          )
        "
        :hide-plus="true"
      />

      <DateRangePicker
        @apply-date="onApplyFilterDateRange"
        @cancel-date="onApplyFilterDateRange"
      />
    </div>

    <div
      class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"
    >
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.summary.totalPromotions'
          )
        "
        :value="getTotalPromos"
        variant="gray"
      />
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.summary.tripTaken'
          )
        "
        :value="getTotalTripTaken"
        variant="gray"
      />
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.promotions.summary.discounts'
          )
        "
        :value="getTotalDiscountAmount"
        variant="gray"
      />
    </div>

    <FSTable
      :fst-id="`riderPromotion`"
      :endpoint="`/dashboard/riders/${$route.params.id}/promotion-records/`"
      :headers="getTableHeaders"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <template v-for="(item, itemIndex) in data">
          <template v-if="getTableMode === 'full'">
            <FSTableRow :key="itemIndex" text-fallback-always>
              <FSTableRowItem
                :text="item.promo_id"
                :truncate="-5"
                :to="{ name: 'ViewPromotion', params: { id: item.pass_id } }"
              />

              <FSTableRowItem :text="item.promo_title" />

              <FSTableRowItem :text="item.promo_category" />

              <FSTableRowItem :text="item.promo_code" />

              <FSTableRowItem :text="item.updated_at" date />

              <FSTableRowItem :text="item.expired_at" date />

              <FSTableRowItem :text="item.num_of_trips_availed" />

              <FSTableRowItem :text="item.num_of_trips_left" />

              <FSTableRowItem :text="`${symbol}${item.discount_amount}`" />
            </FSTableRow>
          </template>
          <template v-if="getTableMode === 'responsive'">
            <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
              <FSTableRowItem>
                <div
                  class="col-span-1 focus:text-gray-400"
                  @click="toggle(itemIndex)"
                >
                  <i
                    class="fas fa-minus-circle"
                    style="color:#d90a20;"
                    v-if="opened.includes(itemIndex)"
                  ></i>
                  <i class="fas fa-plus-circle" v-else></i>
                </div>
              </FSTableRowItem>
              <FSTableRowItem
                :text="item.promo_code"
                :to="{ name: 'ViewPromotion', params: { id: item.pass_id } }"
              />
              <FSTableRowItem :text="item.promo_category" />
              <FSTableRowItem :text="`${symbol}${item.discount_amount}`" />
            </FSTableRow>
            <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
              <td colspan="10">
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoId'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    #{{ item.promo_id | onlyLastFive }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoTitle'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ item.promo_title }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.appliedDate'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      getFormattedDateTime(
                        item.updated_at,
                        'D MMM YYYY, h:mm:ss a'
                      )
                    }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.expiredDate'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      getFormattedDateTime(
                        item.expired_at,
                        'D MMM YYYY, h:mm:ss a'
                      )
                    }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.tripAvailed'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ item.num_of_trips_availed }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.discountAmount'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ `${symbol}${item.discount_amount}` }}
                  </div>
                </div>
              </td>
            </FSTableRow>
          </template>
        </template>
      </template>
    </FSTable>
  </div>
</template>

<script>
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import { getFormattedDateTime } from '@/utils/datetime'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewRiderUserPromotion',
  components: {
    DateRangePicker,
    // OtoEyeIcon,
    // RefundPopup,
    SummaryCard,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    TitlePlus: () => import('@/components/ui/TitlePlus'),
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total_promos: '0',
          trip_taken: '0',
          total_discounted: 0,
        },
        count: {
          total: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoId'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoTitle'
          ),
          width: '10%',
          sort: 'title',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoType'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.code'
          ),
          width: '10%',
          sort: 'code',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.appliedDate'
          ),
          width: '15%',
          sort: 'updated_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.expiredDate'
          ),
          width: '15%',
          sort: 'expired_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.tripAvailed'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.tripLeft'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.discountAmount'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.code'
          ),
          width: '30%',
          sort: 'code',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.promoType'
          ),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.promotions.table.columns.discountAmount'
          ),
          width: '30%',
          sort: null,
        },
      ],
    }
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getTotalPromos() {
      return `${this.indexMetaData?.summary?.total_promos}`
    },
    getTotalTripTaken() {
      return `${this.indexMetaData?.summary?.trip_taken}`
    },
    getTotalDiscountAmount() {
      return `${this.symbol} ${this.indexMetaData?.summary?.total_discounted}`
    },
  },
  mounted() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
